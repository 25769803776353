<template>
  <LxpModal :show.sync="showModal" id="spaces-unpublish-modal" centered>
    <div class="di-content">
      <h5>Unpublish {{ title }}</h5>
      <p>
        Your learning space will no longer visible to the learners anymore.<br />Are
        you sure you want to proceed?
      </p>
    </div>
    <template #footer>
      <div class="di-footer">
        <LxpButton variant="outline-primary" @click="onUnpublish"
          >Unpublish</LxpButton
        >
        <LxpButton variant="primary" @click="showModal = false"
          >Cancel</LxpButton
        >
      </div>
    </template>
  </LxpModal>
</template>

<script>
import { LxpModal, LxpButton } from "didactica";
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    org: {
      type: String,
      default: ""
    }
  },
  components: {
    LxpModal,
    LxpButton
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapGetters(["getOrgList"])
  },
  watch: {
    showModal(newValue) {
      this.$emit("update:show", newValue);
    },
    show() {
      this.showModal = this.show;
    }
  },
  methods: {
    onUnpublish() {
      this.unpublishSpace = {
        org: this.$route.params.org
      };
      this.$store.dispatch("unpublishSpace", this.unpublishSpace).then(() => {
        this.$root.$emit("unPublishLearningSpace", {
          unPublished: true,
          message: "Learning Space got Unpublished. ",
          title: "Unpublished ! ",
          variant: "warning"
        });
      });
      this.showModal = false;
      this.$emit("unpublish-space");
    }
  }
};
</script>

<style lang="scss">
#spaces-unpublish-modal {
  .di-content {
    text-align: center;
    h5 {
      @include subtitle-large;
      color: $brand-neutral-900;
      margin-bottom: 8px;
    }
    p {
      @include body-regular;
      margin-bottom: 0;
    }
  }
  .di-footer {
    @include horizontal-space-between;
    padding-bottom: 16px;
  }
}
</style>

<template>
  <div class="us-layout__wrapper h-100">
    <Header />
    <div class="usl-main__content">
      <template v-if="layout === layouts.NO_SIDEBAR">
        <slot></slot>
      </template>
      <template v-else>
        <div class="usl-main__header" v-if="showLayoutHeader">
          <h5>{{ title }}</h5>
          <!-- Replace button with custom component -->
          <LxpButton v-if="showBtn" @click="$emit('btnClick')">{{
            buttonText
          }}</LxpButton>
        </div>
        <div :class="['usl-main__content--div', { 'mt-0': !showLayoutHeader }]">
          <div class="usl-main__content--div__left">
            <slot></slot>
          </div>
          <div class="usl-main__content--div__right">
            <template v-if="showPublishContent">
              <slot name="publish-content"></slot>
              <publish-content></publish-content>
            </template>
            <template v-else>
              <content-right v-if="!$slots['right-content']"> </content-right
            ></template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { LxpButton } from "didactica";

import Header from "./UserLayout/Header.vue";
import ContentRight from "./UserLayout/ContentRight.vue";
import PublishContent from "./UserLayout/PublishContent.vue";
const LAYOUTS = {
  DEFAULT: "DEFAULT",
  NO_SIDEBAR: "NO_SIDEBAR"
};
export { LAYOUTS };
export default {
  props: {
    title: {
      type: String
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String
    },
    layout: {
      type: String,
      default: LAYOUTS.DEFAULT
    }
  },
  data() {
    return {
      layouts: LAYOUTS
    };
  },
  components: { Header, ContentRight, PublishContent, LxpButton },
  computed: {
    showLayoutHeader() {
      return this.title !== "" || this.buttonText;
    },
    showPublishContent() {
      return (
        this.$route.name === "EditLearningSpace" ||
        this.$route.name === "EditInitiative"
      );
    }
  },
  mounted() {
    this.$keycloak.loadUserProfile().then(res => {
      this.$store.commit("SET_KEYCLOAK_USER_PROFILE", res);
    });
  }
};
</script>

<style lang="scss" scoped>
.us-layout__wrapper {
  overflow: hidden auto;
  background: $brand-primary-50;
  .usl-main__content {
    padding: 48px 64px;
    .usl-main__header {
      @include horizontal-space-between;
      h5 {
        @include h5;
      }
    }
    .usl-main__content--div {
      @include horizontal-space-between;
      align-items: flex-start;
      margin-top: 32px;
      text-align: left;
      .usl-main__content--div__left {
        width: 80%;
        margin-right: 24px;
      }
      .usl-main__content--div__right {
        width: 20%;
      }
    }
  }
}
</style>

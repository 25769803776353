<template>
  <div class="container-fluid us-layout">
    <div class="container-wrapper">
      <div class="us-layout__row">
        <div class="us-layout__left">
          <a class="cw__logo" :href="allConfig.studioBaseURL">
            <img
              src="@/assets/images/layout/header/whoa-logo.svg"
              width="auto"
              height="48"
              alt="logo"
            />
          </a>
          <div class="us-layout__section">
            <b-navbar>
              <b-navbar-nav>
                <b-nav-item :href="`${allConfig.studioBaseURL}/`"
                  >Courses</b-nav-item
                >
                <b-nav-item :href="allConfig.studioInsightsURL"
                  >Analytics</b-nav-item
                >
                <b-nav-item @click="navigateLearningSpace" active
                  >Learning Space</b-nav-item
                >
              </b-navbar-nav>
            </b-navbar>
          </div>
        </div>
        <div class="us-layout__right">
          <b-navbar class="float-right">
            <b-navbar-nav>
              <b-nav-item
                href="https://edx.readthedocs.io/projects/open-edx-building-and-running-a-course/en/open-release-koa.master/developing_course/index.html"
                >Help</b-nav-item
              >

              <!-- Navbar dropdowns -->
              <b-nav-item-dropdown :text="getUserProfile.username" right>
                <b-dropdown-item :href="allConfig.studioBaseURL"
                  >Studio Home</b-dropdown-item
                >
                <b-dropdown-item
                  :href="`${allConfig.studioBaseURL}/maintenance/`"
                  >Maintenance</b-dropdown-item
                >
                <b-dropdown-item @click="logOut">Logout</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["allConfig", "getUserProfile"])
  },
  methods: {
    logOut() {
      this.$keycloak.logout({
        redirectUri: this.allConfig.studioLogoutURL // to logout studio
      });
    },
    redirect(name) {
      this.$router.push({ name });
    },
    navigateLearningSpace() {
      this.$router.push("/learning-space").catch(() => {});
    }
  }
};
</script>
<style lang="scss">
.us-layout {
  background: #ffffff;
  box-shadow: 0px 4px 8px 4px rgb(33 33 33 / 10%);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 64px !important;
  position: relative;
  top: 0;
  left: 0;
  .container-wrapper {
    width: 100%;
    .us-layout__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .us-layout__left {
        display: flex;
        flex-grow: 1;
        .us-layout__section {
          padding-left: 5%;
        }
      }
      .navbar {
        padding: 0;
        .nav-item {
          margin-right: 16px;
          .nav-link {
            padding: 8px 16px;
            @include body-regular(500);
            letter-spacing: 1.25px;
            text-transform: capitalize;
            &.active {
              background: $brand-primary-100;
              mix-blend-mode: normal;
              border-radius: 8px;
              color: $brand-primary-400;
            }
          }
        }
      }
    }
  }
}
</style>

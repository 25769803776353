<template>
  <div class="publish_content-wrapper">
    <TransitionGroup name="fade">
      <div
        v-if="!isPublishedState"
        class="usl-right__publish_content"
        key="published-content"
      >
        <div class="usl-right__status">
          Status :
          <span class="usl-right__intitial-status">{{
            initialPublishStatus
          }}</span>
        </div>

        <div v-if="hasError" class="error_msg--div">
          <span
            ><img
              src="@/assets/images/partner/shared/error-image.svg"
              alt="error-img"
              class="error_msg--img"
            /><span class="error_msg--txt">{{ errorMessage }}</span></span
          >
        </div>
        <LxpButton
          :block="block"
          @click="handleClick"
          :class="{ published: isPublishedState }"
        >
          <img
            src="@/assets/images/learning-space/initiative/loader-medium.svg"
            class="loader"
            v-if="isLoading"
            alt="loader"
          />
          <img
            src="@/assets/images/learning-space/initiative/retry-white.svg"
            v-if="isPublishFailed"
            alt="retry"
          />
          <img
            src="@/assets/images/learning-space/initiative/success-white.svg"
            v-if="isPublishedState"
            alt="success"
          />
          <span class="usl-right__btn--text">
            {{ publishStatus }}
          </span>
        </LxpButton>
        <div class="usl-right__note">
          <p>
            You haven't published your page. If you want that to be reflected in
            the learner side hit “Publish Live”
          </p>
        </div>
        <div class="usl-right__content--top">
          <label>What is my learning space?</label>
          <p>
            In your learning space, students or learners can engage with
            educational materials, collaborate with others, and interact with
            your teachers or instructors to enhance their knowledge and skills
            through the different range of resources you provide.
          </p>
        </div>
      </div>
    </TransitionGroup>

    <TransitionGroup name="fade">
      <div
        v-if="isPublishedState && showPublishedContent"
        class="usl-right__published_content"
        key="unpublished-content"
      >
        <div class="usl-right__status">
          Status :
          <span class="usl-right__intitial-status">{{
            initialPublishStatus
          }}</span>
        </div>
        <div v-if="hasError" class="error_msg--div">
          <span
            ><img
              src="@/assets/images/partner/shared/error-image.svg"
              alt="error-img"
              class="error_msg--img"
            /><span class="error_msg--txt">{{ errorMessage }}</span></span
          >
        </div>
        <div class="usl-right__publish_btn">
          <LxpButton
            :block="block"
            @click="handleClick"
            :class="{ published: isPublishedState }"
          >
            <img
              src="@/assets/images/learning-space/initiative/success-white.svg"
              v-if="isPublishedState"
              alt="success"
            />
            <span class="usl-right__publishbtn--text">
              {{ publishStatus }}
            </span>
          </LxpButton>
        </div>
        <div class="usl-right__unpublish_btn">
          <LxpButton
            :block="block"
            variant="outline-primary"
            @click="unPublishSpaces"
            ><div class="usl-right__unpublishbtn--text">
              Unpublish
            </div>
          </LxpButton>
        </div>
        <div class="usl-right__content--top">
          <label>What is my learning space?</label>
          <p>
            In your learning space, students or learners can engage with
            educational materials, collaborate with others, and interact with
            your teachers or instructors to enhance their knowledge and skills
            through the different range of resources you provide.
          </p>
        </div>
      </div>
    </TransitionGroup>
    <SpacesUnpublishModal
      :show.sync="showSpaceUnpublishModal"
      :title="currentOrg.params.org"
      @unpublish-space="navigateToHome"
    ></SpacesUnpublishModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
import SpacesUnpublishModal from "@/components/LearningSpace/Modals/SpacesUnpublishModal.vue";
export default {
  components: { LxpButton, SpacesUnpublishModal },
  props: {
    block: {
      type: Boolean,
      default: true
    },
    isPublished: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: "",
      isLoading: false,
      isPublishFailed: false,
      isPublishedState: false,
      showPublishedContent: false,
      showSpaceUnpublishModal: false,
      initialPublishStatus: "",
      hasError: false,
      errorMessage:
        "Error trying to publish changes, please check your connection and try again"
    };
  },
  created() {
    if (this.getSpaceStatus === true) {
      this.showPublishedContent = true;
      this.isPublishedState = true;
    }
  },

  computed: {
    ...mapGetters(["getSpacesList"]),
    currentOrg() {
      return {
        params: { org: this.$route.params.org }
      };
    },
    currentSpace() {
      return this.getSpacesList.find(
        space => space.org_short_name === this.currentOrg.params.org
      );
    },
    getSpaceStatus() {
      const curSpace = this.currentSpace;

      if (curSpace !== null && curSpace !== undefined) {
        return curSpace.is_published;
      }
      return null;
    },
    publishStatus() {
      if (this.isPublishedState) {
        return "Published";
      } else if (this.isLoading) {
        return "Publishing";
      } else if (this.isPublishFailed) {
        return "Try Again";
      } else {
        return "Publish Live";
      }
    },
    initialisPublished() {
      if (this.isPublishedState) {
        return "Published";
      } else if (!this.isPublishedState) {
        return "Draft";
      } else {
        return "";
      }
    }
  },
  methods: {
    handleClick() {
      if (this.publishStatus !== "Published") {
        this.isLoading = true;
        this.isPublishFailed = false;
        this.publishSpace = {
          org: this.$route.params.org
        };

        this.$store
          .dispatch("publishSpace", this.publishSpace)
          .then(() => {
            this.isPublishedState = true;
            this.isLoading = false;
            setTimeout(() => {
              this.showPublishedContent = true;
            }, 450);
          })
          .catch(() => {
            this.isPublishFailed = true;
            this.isLoading = false;
            this.hasError = true;
            setTimeout(() => {
              this.hasError = false;
            }, 5000);
          });
      }
    },
    unPublishSpaces() {
      this.showSpaceUnpublishModal = true;
    },
    navigateToHome() {
      this.$router.push({ name: "LearningSpacesHome" });
    }
  }
};
</script>

<style lang="scss" scoped>
.publish_content-wrapper {
  .usl-right__publish_content {
    .usl-right__content--top {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    label {
      @include body-regular(500);
      margin-bottom: 10px;
    }
    p {
      @include body-medium;
    }
  }
  .usl-right__published_content {
    .usl-right__content--top {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    label {
      @include body-regular(500);
      margin-bottom: 10px;
    }
    p {
      @include body-medium;
    }
  }
  .usl-right__status {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  .usl-right__intitial-status {
    font-weight: 400;
  }
  .usl-right__note {
    margin-bottom: 10px;
    margin-top: 10px;
    p {
      font-size: 14px;
      color: $brand-neutral-300;
    }
  }
  .usl-right__btn--text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.25px;
  }
  .published {
    background: $brand-success-400 !important;
    color: $brand-primary-50 !important;
    .loader {
      animation: loader-rotate 1s linear infinite;
    }
  }
  .usl-right__unpublish_btn {
    margin-top: 12px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .error_msg--div {
    color: $brand-neutral-700;
    border: 2px solid $brand-error-400;
    background: $brand-error-100;
    border-radius: 8px;
    padding: 6px 6px;
    margin-bottom: 4%;
    @include body-medium;
    font-style: normal;
    display: flex;
    .error_msg--img {
      display: flex;
      float: left;
      height: fit-content;
      .error_msg--txt {
        display: flex;
        padding-left: 8px;
      }
    }
  }
}
@keyframes loader-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
